body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.listgroupscroll {
    max-height    : 140px!important;
    overflow      : scroll!important;
    margin-bottom : 5px!important;
}


#root > div > div > div.mt-2.card > div.card-body > div > div > button {
    width: 70vw;
}
#root > div > div > div.mt-2.card > div.card-body > div > div > div {
    width: 70vw;
}

#root > div > div > div.mt-2.card > div.card-body > div > div > div > a {
    display: flex;
    justify-content: space-between;
}

.dropdown_flex-container{
    display: flex !important;
    flex-flow: row !important;
    justify-content: center !important;
    align-items: baseline !important;
}
.flex-container {
    display: flex !important;
    flex-flow: row wrap !important;
    justify-content: center !important;
    align-items: baseline !important;
}


#attendees_card_header{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.attendees_listgroup{
    max-height: 60vh;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.instructors_card_body{
    max-height: 20vh;
    overflow: auto;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.uploadspinner{
    margin-top: 10px;
    border: 1px solid red;
    display: flex;
    height: 303px;
}

.uploadspinner svg{
  margin : auto
}

.emergencyinfoAlignHeader{
    align-items: center !important;
}

.loginView_Header{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.tabletContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tabletAttendees{
    width:49% !important;
}

.tabletCamWidth{
    width:49% !important;
}

.tabletCardHeight{
    height:85vh;
}

@media(max-width:1023px) {
    #hideonmobile   {display:none;}
    .tabletCamWidth {width:100% !important;}
}

